export const environment = {
  production: true,
  SERVER_URL: 'https://api.sokoa.com/',
  FILE_ENDPOINT: 'https://api.sokoa.com/api/document/upload',
  DATA_ENDPOINT: 'https://api.sokoa.com/api/document/',
  APPLICATION_NAME: 'Sokoa',
  LANGUAGES: ['fr', 'en', 'es'],

  // Required for cookies management
  DOMAIN: 'appli.sokoa.com',

  firebase: {
    apiKey: 'AIzaSyCy2776eR1ABQFUQ5UPlliX5GmuUji88Hc',
    authDomain: 'sokoa-d4f04.firebaseapp.com',
    projectId: 'sokoa-d4f04',
    storageBucket: 'sokoa-d4f04.appspot.com',
    messagingSenderId: '393040688236',
    appId: '1:393040688236:web:1525c8de8e0ec784439449',
    measurementId: 'G-XPK8R5HFYE',
    DEBUG_MODE: false,
  },
};
